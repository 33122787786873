import {CDN_LINK} from "@globalConfig";
import stl from "./SearchHeader.module.css";
import UnbxdMobileTextSearch
    from "../../UnbxdTextSearch/UnbxdMobileTextSearch/UnbxdMobileTextSearch";
import useHelpers from "@hooks/useHelpers";

const SearchHeader = () => {
    const {createUrl} = useHelpers();
    return (
        <header className={[stl.wrapper, "m-show"].join(" ")}>
            <a href={createUrl("/")}>
                <img
                    width={57}
                    height={26}
                    src={CDN_LINK + "/assets/images/logoN.svg"}
                    alt="Syarah logo"
                />
            </a>

            <UnbxdMobileTextSearch/>
        </header>
    );
};

export default SearchHeader;
