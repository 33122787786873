import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import stl from "./UnbxdMobileTextSearch.module.css";
import useUnbxdTextSearch from "../../../../../../../common/hooks/useUnbxdTextSearch";
import loadable from "@loadable/component";
import {useTranslation} from "@i18n";
import { CDN_LINK } from "@globalConfig";
import useHelpers from "@hooks/useHelpers";
import {useAuthActions} from "@/_MiniApps/auth/AuthContext";

const fallback = {
  fallback: null,
};

const UnbxdMobileTextSearchPopUp = loadable(
  () => import("./UnbxdMobileTextSearchPopUp"),
  fallback
);

const UnbxdMobileTextSearch = ({  }) => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);

  const [searchParams] = useSearchParams();
  const searchTermParam = searchParams.get("text");
  const {createUrl} = useHelpers();
  const {syncSearchHistoryOnLogin} = useAuthActions(); // Access the function
  const navigate = useNavigate();

  const {
    searchTerm,
    searchTermSuggestions,
    handleSearchTermChange,
    handleTextSearch,
    setSearchTerm,
    setSearchTermSuggestions,
  } = useUnbxdTextSearch( setShowPopup);

  useEffect(() => {
    if (showPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showPopup]);

  useEffect(() => {
    if (!searchTermParam) {
      setSearchTerm("");
      setSearchTermSuggestions([]);

      return;
    }

    setSearchTerm(searchTermParam);
  }, [searchTermParam]);

  return (
    <>
      <div
        className={[stl.textSearch, "abSearchTextClass"].join(" ")}
        onClick={() => {setShowPopup(true);syncSearchHistoryOnLogin()}}>
        {!searchTermParam ? (
          <>
            <img
              src={CDN_LINK + "/assets/images/text-search-icon.svg"}
              width={15}
              height={15}
              alt="text-search-icon"
              aria-label="text-search-icon"
            />
            <span className="interActiveGray">
              {t("ابحث عن الماركة او الموديل", { ns: "web_search" })}
            </span>
            <button className={`${stl.searchButton} interActiveBackBlue`}>
              {t("بحث", { ns: "web_search" })}
            </button>
          </>
        ) : (
          <>
            <img
              src={CDN_LINK + "/assets/images/text-search-icon-active.svg"}
              alt=""
            />
            <span className={stl.active}>{searchTermParam}</span>
            <img
              src={CDN_LINK + "/assets/images/clear.svg"}
              alt=""
              className={`${stl.clear} hasEvents`}
              onClick={(e) => {
                e.stopPropagation();
                setSearchTerm("");
                setSearchTermSuggestions([]);

                navigate(createUrl("/filters"));
              }}
            />
          </>
        )}
      </div>

      {showPopup && (
        <UnbxdMobileTextSearchPopUp
          searchTerm={searchTerm}
          imgClick={() => {
            setShowPopup(false);
            setSearchTerm("");
          }}
          handleSearchTermChange={handleSearchTermChange}
          handleTextSearch={handleTextSearch}
          searchTermSuggestions={searchTermSuggestions}
        />
      )}
    </>
  );
};

export default UnbxdMobileTextSearch;
